<template>
  <div class="settlementDimension_container">
    <div class="settlementDimension_box">
      <span>维度</span>
      <a-radio-group v-model="searchquery.settlementDimension">
        <a-radio-button
          v-for="(item, inx) in radioArr"
          :key="inx"
          :value="item.value"
          @click="settlementDimensionTab(item.value)"
        >
          {{ item.name }}
        </a-radio-button>
      </a-radio-group>
    </div>
    <div class="settlementDimension_con">
      <span>筛选</span>
      <div class="settlementDimension_con_select">
        <a-range-picker
          class="search_item"
          format="YYYY-MM-DD"
          :placeholder="['Start', 'End']"
          @change="ChangeDate"
          :value="rangePickerDate"
          :allowClear="false"
          :ranges="{
            昨日: [initDate(1, 1).start, initDate(1, 1).end],
            最近一周: [initDate(6, 0).start, initDate(6, 0).end],
            最近一个月: [initDate(30, 0).start, initDate(30, 0).end]
          }"
          :disabledDate="disabledDate"
        >
        </a-range-picker>
        <m-select-more
          class="search_item"
          v-model="searchquery.userIdList"
          :allData="mediaAccountList"
          :searchById="true"
          :hasIcon="true"
          :showId="false"
          width="450px"
          label="媒体账号"
          @change="changeMediaIds"
        />
        <m-select-more
          class="search_item"
          v-model="searchquery.appIdList"
          :allData="appList"
          :searchById="true"
          :hasIcon="true"
          :showId="true"
          width="450px"
          label="应用"
          @change="changeAppIds"
        />
        <m-select-more
          v-if="searchquery.settlementDimension !== '1'"
          class="search_item"
          v-model="searchquery.placeIdList"
          :allData="placeList"
          :searchById="true"
          :hasIcon="true"
          :showId="true"
          width="450px"
          label="广告位"
        />
        <m-select-more
          v-if="searchquery.settlementDimension === '3'"
          class="search_item"
          v-model="searchquery.platIdList"
          :allData="platList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="广告平台"
          :showSelectedPart="true"
          width="450px"
          :canSelectAll="true"
          @change="changePlatId"
        />
        <m-select-more
          v-if="searchquery.settlementDimension === '3'"
          class="search_item"
          v-model="searchquery.platAccountIdList"
          :allData="platAccountList"
          :searchById="false"
          :hasIcon="false"
          :showId="false"
          label="广告平台账户"
          :showSelectedPart="true"
          width="450px"
          :canSelectAll="true"
        />
        <a-select class="search_item" v-model="searchquery.auditSts" allowClear placeholder="请选择发布状态">
          <a-select-option v-for="item in auditStsList" :key="item.id">
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="handleAutoSearch">查询</a-button>
      </div>
    </div>
    <!-- 搜索、操作按钮 -->
    <div style="display: flex; justify-content: space-between">
      <el-popover placement="bottom-start" v-model="popoverVisible">
        <div style="padding: 5px">
          <a-radio style="font-weight: bold; color: #333" v-model="radioValue">批量</a-radio>
          <div style="display: flex; margin-top: 10px">
            <span v-for="item of batchOption" :key="item.id">
              <el-tooltip effect="dark" :disabled="!btnDisabled" content="不能执行该操作" placement="right-start">
                <el-button class="btnHover" @click="batchOprateALL(item.id)" :disabled="btnDisabled">{{
                  item.name
                }}</el-button>
              </el-tooltip>
            </span>
          </div>
        </div>
        <a-button slot="reference" :disabled="!selectedRowKeys.length > 0">批量操作<a-icon type="down" /></a-button>
      </el-popover>
      <div>
        <a-button type="link" @click="handleClickCustTagsBtn"><a-icon type="edit" />自定义指标</a-button>
        <a-button :loading="downLoading" type="link" @click="downloadReport"><a-icon type="download"/>导出</a-button>
      </div>
    </div>

    <!-- 表格 -->
    <div class="sonfig_tables m-t-20">
      <a-table
        :loading="loadings"
        class="m_table_cust"
        size="middle"
        @change="handleSort"
        ref="tableSort"
        :data-source="data"
        :scroll="{ x: true }"
        :columns="columns"
        :rowKey="(record) => record.keyNum"
        :pagination="false"
        :rowClassName="rowClassName"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          getCheckboxProps: getCheckboxProps
        }"
        bordered
      >
        <!-- 应用 -->
        <template slot="appName" slot-scope="appName, record">
          <template v-if="searchquery.settlementDimension !== '3'">
            <span :class="['pointer', 'fontColor']" v-if="record.type !== 'total'" @click="clickAppName(record.appId)">
              <IosSvg v-if="record.os === '0'" class="svg-icon" />
              <AndroidSvg v-else class="svg-icon" />
              {{ appName }}
            </span>
          </template>
          <template v-else>
            <span v-if="record.type !== 'total'">
              <IosSvg v-if="record.os === '0'" class="svg-icon" />
              <AndroidSvg v-else class="svg-icon" />
              {{ appName }}
            </span>
          </template>
        </template>
        <!-- 广告位 -->
        <template slot="placeName" slot-scope="placeName, record">
          <template v-if="searchquery.settlementDimension === '2'">
            <span :class="['pointer', 'fontColor']" v-if="record.type !== 'total'" @click="clickPlaceName(record)">{{
              placeName
            }}</span>
          </template>
          <template v-else>
            <span v-if="record.type !== 'total'">{{ placeName }}</span>
          </template>
        </template>
        <!-- 状态 -->
        <template slot="auditSts" slot-scope="auditSts, record">
          <span v-if="record.type !== 'total'" :class="[getauditSts(record, 2)]">{{ getauditSts(record, 1) }}</span>
        </template>
        <!-- 操作 -->
        <template slot="action" slot-scope="action, record" v-if="record.type !== 'total'">
          <div v-if="record.type !== 'total'" class="pointer action-slot">
            <span @click="batchConfigure(record)" class="btn">配置</span>
            <span class="line">|</span>
            <span @click="batchOprate(record, 1)" class="btn">发布</span>
            <span class="line">|</span>
            <span @click="batchOprate(record, 2)" class="btn">回收</span>
          </div>
          <span v-else>--</span>
        </template>
      </a-table>
      <m-empty v-if="data.length <= 1" style="height: 70vh; width: 100%">
        <template #description>暂无数据！</template>
      </m-empty>
      <a-pagination
        v-if="data.length > 1"
        class="pagination"
        show-size-changer
        :current="querData.page"
        :show-total="(total) => `共 ${total} 条`"
        :total="total"
        @change="handleCurrentPage"
        @showSizeChange="handleChangePageSize"
      />
    </div>
    <!-- 配置弹窗 -->
    <ConfigModal
      :placeInfo="placeInfo"
      :placeInfoArr="placeInfoArr"
      :visible="configurefirmVisible"
      :ifALL="ifALL"
      @handleCancel="handleCancel"
      @modalCancel="configurefirmVisible = false"
      :type="Number(searchquery.settlementDimension)"
      @changeInfo="changeInfo"
    />
    <!-- 提示确认弹窗 -->
    <a-modal
      v-model="popconfirmVisible"
      title="提示"
      :confirm-loading="confirmLoading"
      @ok="batchOperationSts"
      @cancel="handleCancel"
    >
      <div class="batchModel">
        <a-icon class="icon" type="exclamation-circle" />
        <p>{{ popTips }}</p>
      </div>
    </a-modal>
    <!-- 自定义指标 -->
    <CustomListModal
      v-if="custTagsVisible"
      :type="custType"
      :visible="custTagsVisible"
      :dataAttributes="disAttributes"
      :mediaDataAttributes="mediaDisAttributes"
      :sureArr="allcheckArr"
      @modalCancel="custTagsVisible = false"
      @changeCustTags="changeCustTags"
    />
  </div>
</template>
<script>
import {
  appList, // 应用下拉框
  mediaAccountList, // 媒体账户下拉框
  appSettlementPager, // 媒体结算列表 应用
  placeSettlementPager, // 媒体结算列表 广告位
  platSettlementPager, // 媒体结算列表 广告平台
  batchUpdateSts,
  getPlatAccounts,
  updateSts // 媒体结算 列表发布、回收
} from '@/api/reportdatas'
import mixDate from '@/mixins/initDate'
import CustomListModal from '@/components/common/CustomList'
import { platformLinkList } from '@/api/aggregate'
import ConfigModal from './components/configModal'
import request from '@/utils/request'
import {
  columns,
  radioArr,
  auditStsList,
  Application,
  mediaApplication,
  Advertising,
  mediaAdvertising,
  Platform,
  mediaPlatform,
  batchOption
} from './list'
import mixGetList from '@/mixins/getList'
import { getDuration } from '@/utils/duration'
import IosSvg from '@/assets/icons/ios.svg?inline'
import excel from '@/utils/excel'
import AndroidSvg from '@/assets/icons/android.svg?inline'
import localDataName from '@/utils/localDataName'

export default {
  name: 'Alliance',
  components: { CustomListModal, ConfigModal, IosSvg, AndroidSvg },
  mixins: [mixDate, mixGetList],
  data () {
    return {
      downLoading: false,
      radioArr,
      searchquery: {
        auditSts: undefined,
        appIdList: undefined,
        userIdList: undefined
      },
      querData: {
        page: 1,
        limit: 10
      },
      sortquery: {
        sortBy: undefined,
        sortOrder: undefined
      },
      sortInfo: {
        prop: undefined,
        sortOrder: undefined
      },
      Application,
      mediaApplication,
      Advertising,
      mediaAdvertising,
      Platform,
      mediaPlatform,
      columns, // 列表
      auditStsList, // 发布状态下拉
      loadings: false,
      radioValue: true,
      data: [], // list data
      popoverVisible: false, // 批量操作弹窗
      detailsInfo: {}, // 单独操作参数
      detailsInfoList: [
        {
          // 批量操作参数
          date: '',
          auditSts: ''
        }
      ],
      popconfirmVisible: false, // 删除确认弹窗
      total: 0,
      mediaAccountList: [],
      rangePickerDate: null, // 日期
      selectedRowidList: [],
      confirmLoading: false,
      batchOption, // 批量操作弹窗操作项
      selectedRowKeys: [], // 选中的数据
      custTagsVisible: false,
      custType: '4',
      popTips: '',
      platAccountList: [],
      configurefirmVisible: false,
      placeInfo: {},
      placeInfoArr: [],
      ifALL: false,
      disAttributes: [],
      mediaDisAttributes: []
    }
  },
  watch: {
    $route: {
      handler (val) {
        const query = val.query
        const ifAppArr = localStorage.getItem(localDataName.mediasettleAppLocalName)
        const ifAdvArr = localStorage.getItem(localDataName.mediasettleAdvLocalName)
        const ifPlatArr = localStorage.getItem(localDataName.mediasettlePlatLocalName)
        if (query.target) {
          // 否则按照点击tab获取
          const list = {
            Application: this.Application,
            Advertising: this.Advertising,
            Platform: this.Platform
          }
          const mediaList = {
            Application: this.mediaApplication,
            Advertising: this.mediaAdvertising,
            Platform: this.mediaPlatform
          }
          /**
           * 初始化列表字段
           * 应用维度 ： 应用
           * 广告位维度： 应用、广告位
           * 广告平台维度： 应用、广告位、广告平台、平台账户
           * ***/
          const list1 = {
            Application: columns.filter(
              (item) =>
                item.dataIndex !== 'platName' && item.dataIndex !== 'placeName' && item.dataIndex !== 'platAccountName'
            ),
            Advertising: columns.filter(
              (item) => item.dataIndex !== 'platName' && item.dataIndex !== 'platAccountName'
            ),
            Platform: columns
          }
          const list2 = {
            Application: '1',
            Advertising: '2',
            Platform: '3'
          }
          const list3 = {
            Application:
              (localStorage.getItem(localDataName.mediasettleAppLocalName) &&
                JSON.parse(localStorage.getItem(localDataName.mediasettleAppLocalName) || [])) ||
              [],
            Advertising:
              (localStorage.getItem(localDataName.mediasettleAdvLocalName) &&
                JSON.parse(localStorage.getItem(localDataName.mediasettleAdvLocalName) || [])) ||
              [],
            Platform:
              (localStorage.getItem(localDataName.mediasettlePlatLocalName) &&
                JSON.parse(localStorage.getItem(localDataName.mediasettlePlatLocalName) || [])) ||
              []
          }
          const list4 = {
            Application: ifAppArr,
            Advertising: ifAdvArr,
            Platform: ifPlatArr
          }
          this.searchquery.settlementDimension = list2[query.target]
          this.disAttributes = list[query.target] // 数据指标
          this.mediaDisAttributes = mediaList[query.target] // 媒体指标
          // sureArrs 已选列表，如缓存中有弹窗中显示缓存，没有展示全部 = arrAttributes
          // this.allcheckArr = list[query.target].filter(item => item.checked) // 已选中的指标
          // 数据指标 and 媒体指标
          this.allcheckArr = list4[query.target]
            ? list3[query.target]
            : [
                ...(list4[query.target] ? list3[query.target] : list[query.target].filter((item) => item.checked)),
                ...(list4[query.target] ? list3[query.target] : mediaList[query.target].filter((item) => item.checked))
              ]
          this.columns = [...list1[query.target], ...this.allcheckArr] // 表格列表
          // 初始化排序信息
          this.initSortQuery()
          this.columns.forEach((item) => {
            if (item.sorter) {
              item.sortOrder = this.sortInfo.prop === item.dataIndex ? this.sortInfo.sortOrder : false
            }
          })
        } else {
          // 初始化展示应用维度
          this.searchquery.settlementDimension = '1'
          this.disAttributes = this.Application
          this.mediaDisAttributes = this.mediaApplication
          this.allcheckArr = ifAppArr
            ? (localStorage.getItem(localDataName.mediasettleAppLocalName) &&
                JSON.parse(localStorage.getItem(localDataName.mediasettleAppLocalName) || [])) ||
              []
            : [
                ...this.Application.filter((item) => item.checked),
                ...this.mediaApplication.filter((item) => item.checked)
              ]
          this.columns = [...columns, ...this.allcheckArr]
          this.columns = this.columns.filter(
            (item) =>
              item.dataIndex !== 'platName' && item.dataIndex !== 'placeName' && item.dataIndex !== 'platAccountName'
          )
        }
      },
      immediate: true,
      deep: true
    }
  },
  computed: {
    btnDisabled () {
      // 若选中列表中有已发布，则不能批量操作，批量弹窗按钮置灰
      return this.selectedRowidList.some((item) => item.auditSts === '2')
    }
  },
  created () {
    // 初始化时间
    const { endDate, startDate, end, start } = this.initDate(7, 1)
    this.rangePickerDate = [start, end]
    this.searchquery.dateEnd = endDate
    this.searchquery.dateStart = startDate
    this.handleRequest() // 获取列表
    this.getmediaAccountList() // 获取媒体账户下拉框
    this.getAppList() // 获取应用
    this.getPlatAccounts([])
    this.getPlatList() /// 获取广告平台
    this.getPlaceList()
  },
  methods: {
    async downloadReport () {
      this.downLoading = true
      const target = this.$router.currentRoute.query.target
      let type = 1
      if (target === 'Platform') {
        type = 3
      } else if (target === 'Advertising') {
        type = 2
      }
      const appArrNameList = ['日期', '应用']
      const placeArrNameList = ['日期', '应用', '广告位']
      const platArrNameList = ['日期', '应用', '广告位', '广告平台', '平台账户']
      const appArrNCodeList = ['date', 'appName']
      const placeArrNCodeList = ['date', 'appName', 'placeName']
      const platArrNCodeList = ['date', 'appName', 'placeName', 'platName', 'platAccountName']
      const currentNameList = this.allcheckArr.map((item) => item.title)
      const currentCodeList = this.allcheckArr.map((item) => item.dataIndex)
      const nameList = type === 1 ? appArrNameList : type === 2 ? placeArrNameList : platArrNameList
      const codeList = type === 1 ? appArrNCodeList : type === 2 ? placeArrNCodeList : platArrNCodeList
      try {
        const resp = await this.exportToExcel({
        ...this.searchquery,
        ...this.sortquery,
        type,
        codeList: [...codeList, ...currentCodeList],
        nameList: [...nameList, ...currentNameList]
      })
        await excel(resp, `媒体结算报表.xlsx`)
      } catch (e) {
        console.log(e, '导出excel错误信息')
      } finally {
        this.downLoading = false
      }
    },
    exportToExcel (query) {
      return request.post('/settlement/downloadSettlementReport', query, {
        responseType: 'blob'
      })
    },
    // 点击列表应用名称
    clickAppName (data) {
      const type = this.searchquery.settlementDimension
      this.searchquery.appIdList = [data]
      this.$router.push({
        name: 'mediasettle',
        query: {
          target: type === '1' ? 'Advertising' : type === '2' ? 'Platform' : ''
        }
      })
      this.querData.page = 1
      this.searchquery.userIdList = undefined
      this.searchquery.settlementDimension = String(+this.searchquery.settlementDimension + 1)
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    },
    // 点击列表广告位名称
    clickPlaceName (data) {
      this.$router.push({
        name: 'mediasettle',
        query: {
          target: 'Platform'
        }
      })
      // this.searchquery.appIdList = [data.appId]
      this.searchquery.placeIdList = [data.placeId]
      this.searchquery.settlementDimension = '3'
      this.querData.page = 1
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    },
    // 自定义指标
    handleClickCustTagsBtn () {
      const list = {
        1: '4',
        2: '5',
        3: '6'
      }
      this.custType = list[this.searchquery.settlementDimension]
      this.custTagsVisible = true
    },
    // 自定义弹窗保存后触发改事件
    changeCustTags (dragArr, targetArr) {
      const type = this.searchquery.settlementDimension
      const list1 = {
        1: columns.filter(
          (item) =>
            item.dataIndex !== 'platName' && item.dataIndex !== 'placeName' && item.dataIndex !== 'platAccountName'
        ),
        2: columns.filter((item) => item.dataIndex !== 'platName' && item.dataIndex !== 'platAccountName'),
        3: columns
      }
      this.allcheckArr = dragArr
      this.columns = [...list1[type], ...dragArr] // 表格列表
    },
    // 点击tab处理列表list
    async settlementDimensionTab (data) {
      await this.$router.push({
        name: 'mediasettle',
        query: {
          target: data === '1' ? 'Application' : data === '2' ? 'Advertising' : data === '3' ? 'Platform' : ''
        }
      })
      this.searchquery.settlementDimension = data
      this.querData.page = 1
      this.getAppList() // 获取应用
      this.getPlatAccounts([])
      this.getPlaceList()
      this.getmediaAccountList() // 获取媒体账户下拉框
      this.getPlatList() /// 获取广告平台
      this.handleRequest() // 列表请求
    },
    // 获取广告平台账户下拉
    async getPlatAccounts (query) {
      const resp = await getPlatAccounts({
        platIdList: query
      })
      if (resp.code === 200) {
        const arr = resp.data || []
        this.platAccountList = arr
      }
    },
    // 获取媒体账户下拉框
    async getmediaAccountList () {
      const query = { dimension: this.searchquery.settlementDimension }
      const resp = await mediaAccountList(query)
      if (resp.code === 200) {
        const arr = resp.data || []
        this.mediaAccountList = arr
      }
    },
    // 时间修改
    ChangeDate (date, dateString) {
      this.rangePickerDate = dateString
      const [dateStart, dateEnd] = dateString
      this.searchquery.dateStart = dateStart
      this.searchquery.dateEnd = dateEnd
    },
    // 广告平台改变查广告平台账户
    async changePlatId (ids) {
      if (ids.length === 0) {
        // 查所有
        await this.getPlatAccounts((ids = []))
      } else {
        await this.getPlatAccounts(ids)
      }
    },
    // 获取应用
    async getAppList () {
      const settlement = this.searchquery.settlementDimension
      const query = { dimension: settlement }
      const resp = await appList(query)
      if (resp.code === 200) {
        const arr = resp.data || []
        this.appList = arr
        // 获取广告位
        settlement !== '1' && this.getPlaceListByAppIds(arr.map((item) => item.id))
      }
    },
    // 应用改变查广告位
    async changeAppIds (ids) {
      if (ids.length === 0) {
        // app无值 查所有应用
        await this.getPlaceListByAppIds(this.appList.map((item) => item.id))
      } else {
        // app选了 通过选了的app查place列表
        await this.getPlaceListByAppIds(ids)
      }
    },
    // 改变媒体账号 查应用
    async changeMediaIds (userIds) {
      const query = {}
      if (userIds.length === 0) {
        query.dimension = this.searchquery.settlementDimension
      } else {
        query.dimension = this.searchquery.settlementDimension
        query.userIds = userIds
      }
      const resp = await appList(query)
      if (resp.code === 200) {
        const arr = resp.data || []
        this.appList = arr
      }
    },
    // 获取广告平台
    async getPlatList () {
      const resp = await platformLinkList()
      if (resp.code === 200) {
        const arr = resp.data || []
        this.platList = arr
      }
    },
    // 不可选时间
    disabledDate (current) {
      // 默认大于今天的时间 小于2021.9
      return (current && current < this.initDate(getDuration(), 0).start) || current > this.initDate(0, 1).end
    },
    // 汇总行禁选
    getCheckboxProps (record) {
      return {
        props: {
          disabled: record.type === 'total'
        }
      }
    },
    // auditSts, 状态颜色
    getauditSts (record, type) {
      const list1 = {
        A: '已发布',
        S: '未发布',
        P: '部分已发布'
      }
      const list2 = {
        A: 'red',
        S: 'green',
        P: 'gray'
      }
      const list = type === 1 ? list1 : list2
      return list[record.auditSts]
    },
    // 点击选择columns
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowidList = selectedRows
      this.selectedRowKeys = selectedRowKeys
    },
    // 汇总行不展示复选框
    rowClassName (record, index) {
      if (index === 0) {
        return 'all_col'
      }
    },
    // 搜索
    handleAutoSearch () {
      this.loading = true
      this.querData.page = 1
      //  请求列表
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    },
    // 清空筛选项 后请求列表
    handleRequest () {
      this.searchquery.appIdList = undefined
      this.searchquery.placeIdList = undefined
      this.searchquery.platIdList = undefined
      this.searchquery.userIdList = undefined
      this.searchquery.auditSts = undefined
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    },
    // 点击列表配置按钮
    batchConfigure (record) {
      this.configurefirmVisible = true
      this.ifALL = false // 标识列表内点击配置
      this.selectedRowKeys = [] // 多选清空
      this.placeInfo = record
    },
    // 点击列表发布、回收按钮
    batchOprate (data, type) {
      // type 1: 发布 2: 回收 区分发布、回收按钮
      this.ifALL = false // 记录行内操作还是批量
      this.popconfirmVisible = true // 确认弹窗
      this.popoverVisible = false // 批量操作提示弹窗
      const { id, appId = '', placeId = '', date = '', platId = 0, platAccountId = '' } = data
      const types = type === 1 ? 'A' : 'S' // A=发布 S=回收
      this.popTips = this.getTips(type)
      const obj = {
        id: Number(id),
        date,
        appId,
        placeId,
        type: this.searchquery.settlementDimension,
        auditSts: types
      }
      const place = this.searchquery.settlementDimension === '2' // tab是广告位
      const plat = this.searchquery.settlementDimension === '3' // tab是广告平台
      /**
       * placeId 广告位id，广告位维度必填
       * plat 广告平台id，广告平台维度必填
       * platAccountId 广告账户id，广告平台维度必填
       ****/
      this.detailsInfo = place
        ? { ...obj, ...{ placeId } }
        : plat
        ? { ...obj, ...{ plat: Number(platId), platAccountId: Number(platAccountId) } }
        : obj
    },
    // 发布、回收提示
    getTips (data) {
      const type = this.searchquery.settlementDimension
      const fabu = {
        1: `在应用维度下发布数据将同步发布该广告位下所有广告平台的数据，请依据自身需求选择数据发布维度，数据发布之后将同步至媒体账户。`,
        2: `在广告位维度下发布数据将同步发布该广告位下所有广告平台的数据，请依据自身需求选择数据发布维度，数据发布之后将同步至媒体账户。`,
        3: `请依据自身需求选择数据发布维度，该广告平台数据发布之后将同步至媒体账户。`
      }
      const huishou = {
        1: `在应用维度下回收数据将同步回收该广告位下所有广告平台的数据，请依据自身需求选择数据回收维度。数据回收之后媒体账户将看不到该数据。`,
        2: `在广告位维度下回收数据将同步回收该广告位下所有广告平台的数据，请依据自身需求选择数据回收维度。数据回收之后媒体账户将看不到该数据。`,
        3: `请依据自身需求选择数据回收维度，该广告平台数据回收之后媒体账户将看不到该数据。`
      }
      const list = {
        1: fabu[type],
        2: huishou[type]
      }
      return list[data]
    },
    // 点击批量操作弹窗发布、回收按钮
    batchOprateALL (id) {
      this.ifALL = true // 记录行内操作还是批量
      this.popoverVisible = false // 批量操作提示弹窗
      if (id === '0') {
        this.configurefirmVisible = true
        this.placeInfoArr = this.selectedRowidList
        return
      }
      this.popTips = this.getTips(Number(id))
      this.popconfirmVisible = true // 确认弹窗
      const types = id === '1' ? 'A' : 'S' // A=发布 S=回收
      const settlementRuleVos = this.selectedRowidList.map(({ id, date, placeId, appId, plat, platAccountId }) => {
        return { id: Number(id), appId, plat, placeId, date, platAccountId }
      })
      this.detailsInfo = {
        type: this.searchquery.settlementDimension,
        auditSts: types,
        settlementRuleVos: settlementRuleVos || []
      }
    },
    // 提示弹窗取消
    handleCancel () {
      this.selectedRowKeys = [] // 多选清空
    },
    // 提示弹窗点击确定 发布回收
    async batchOperationSts () {
      // batchUpdateSts -- 批量发布回收  updateSts -- 发布回收
      const joggle = this.ifALL ? batchUpdateSts : updateSts
      const res = await joggle(this.detailsInfo)
      if (res.code === 200) {
        this.$message.success('修改成功')
        this.querData.page = 1
        this.getDataDetailsReport({
          ...this.searchquery,
          ...this.querData,
          ...this.sortquery
        })
        this.selectedRowKeys = [] // 多选清空
        this.popconfirmVisible = false
      }
    },
    changeInfo () {
      this.querData.page = 1
      this.selectedRowKeys = [] // 多选清空
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    },
    // 点击批量操作按钮
    batchOperation () {
      this.popoverVisible = true
    },
    // 初始化排序（切换维度）
    initSortQuery () {
      this.sortInfo = {
        prop: undefined,
        sortOrder: undefined
      }
      this.sortquery = {
        sortBy: undefined,
        sortOrder: undefined
      }
    },
    // 远程排序
    handleSort (pagination, filters, sorter) {
      // 排序字段对象
      const sortList = {
        impress: 2,
        click: 3,
        ecpm: 6,
        revenue: 7,
        request: 4,
        response: 5
      }
      // 保存排序属性对象
      this.sortInfo = {
        // 排序属性
        prop: sorter.field,
        // 排序方式
        sortOrder: sorter.order
      }
      // 排序属性
      this.sortquery.sortBy = sortList[sorter.field] || undefined
      // 排序方式
      this.sortquery.sortOrder = sorter.order === 'ascend' ? 'asc' : sorter.order === 'ascend' ? 'desc' : undefined
      // 控制渲染状态
      this.columns.forEach((item) => {
        if (item.dataIndex === sorter.field) {
          item.sortOrder = sorter.order ? sorter.order : false
        }
      })
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    },
    // 获取tablist
    async getDataDetailsReport (data) {
      this.loadings = true
      const list = {
        1: appSettlementPager,
        2: placeSettlementPager,
        3: platSettlementPager
      }
      const res = await list[this.searchquery.settlementDimension](data)
      if (res.code === 200 && res.data) {
        const tempData = res.data.items || []
        const obj = {
          type: 'total',
          date: '总计：' + res.data.total + '条记录'
        }
        this.data = [...[{ ...obj, ...res.data.aggregate }], ...tempData]
        // 给列表添加唯一标识
        this.data.map((item) => {
          item.keyNum = Math.random()
        })
      }
      this.loadings = false
      this.selectedRowKeys = [] // 多选清空
      this.total = res.data && res.data.total
    },
    // 分页
    handleCurrentPage (page) {
      this.querData.page = page
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    },
    handleChangePageSize (current, size) {
      this.querData.page = 1
      this.querData.limit = size
      this.getDataDetailsReport({
        ...this.searchquery,
        ...this.querData,
        ...this.sortquery
      })
    }
  }
}
</script>
<style lang="less" scoped>
@import '~@/assets/less/mixins/redefineAnt.less';

.m-t-20 {
  margin-top: 20px;
}

.settlementDimension_box,
.settlementDimension_con {
  display: flex;
  align-items: center;

  span {
    width: 70px;
    color: #333;
    font-weight: 500;
  }

  .settlementDimension_con_select {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 20px 0;

    .search_item {
      padding: 10px 10px 0px 0;
      width: 220px;
      display: inline-block;
    }

    .btn {
      margin: 10px 10px 0px 0;
      background: @primary-color;
    }
  }
}

::v-deep {
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: fade(@primary-color, 10%);
  }

  .all_col .ant-table-selection-column span {
    display: none;
  }

  .ant-modal {
    top: 22%;

    .ant-modal-body {
      .batchModel {
        padding: 30px;
        display: flex;
        align-items: center;
      }

      .icon {
        color: #fa8b15;
        font-size: 22px;
        margin-right: 20px;
      }

      p {
        margin-bottom: 0;
        line-height: 24px;
      }
    }

    .ant-modal-footer {
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
      border-top: none;
      padding: 14px;
    }

    .ant-btn,
    .ant-btn-primary {
      width: 90px;
    }
  }

  .input[type='checkbox']:first-child {
    display: none;
  }

  .aRadioBtn();

  .btnHover {
    border: none;
    box-shadow: none;

    &:hover {
      color: @primary-color;
      font-weight: 600;
      background-color: #fff;
      // border-radius: 16px;
    }
  }

  .el-btn[disabled] {
    background: #fff;
  }

  .m_table_cust table tbody tr:first-child {
    color: #999999;
    background: #fafafa;
  }
}

.settlementDimension_container {
  background: #fff;
  border-radius: 5px 5px 0 0;
  padding: 20px;
  margin: 0 10px;

  .search {
    .btn {
      background: fade(@primary-color, 10%);
      border: 1px solid @primary-color;
      color: @primary-color;
    }
  }

  .sonfig_tables {
    height: 100%;

    .m_table_cust {
      th.is-leaf {
        border-bottom: none;
      }
    }

    .red {
      color: #f83366;
    }

    .green {
      color: #15ad31;
    }

    .gray {
      color: #666464;
    }

    .pagination {
      margin-top: 20px;
    }

    // .btn {
      // margin: 0 5px;
    // }
    .svg-icon{
      width: 13px;
      height: 13px;
    }
  }

  .pointer {
    cursor: pointer;
  }
  .action-slot{
      .btn{
        color: @primary-color;
        cursor: pointer;
      }
      .line{
        color: #ccc;
      }
      span{
        margin-right: 10px;
      }
    }
}
</style>
