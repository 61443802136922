<template>
  <a-modal v-model="popconfirmVisible" @onCancel="handleCancel" @ok="handleSubmit" dialogClass="batch_advertiser_modal">
    <template
      slot="title"
    >配置
      <a-popover trigger="hover">
        <template #content>
          <p>如需对日活进行配置，可前往应用页面进行配置；</p>
          <p>如需对请求、填充进行配置，可以前往广告位页面进行相关配置；</p>
          <p>如需对展示、点击、收益进行配置，可前往广告平台页面；</p>
        </template>
        <a-icon style="fon-size: 12px; cursor: pointer; padding: 0 5px" type="question-circle" />
      </a-popover>
    </template>
    <div class="add_advertiser_wrapper">
      <a-form-model ref="ruleForm" :model="query" :rules="rules" v-bind="layout">
        <a-form-model-item has-feedback label="日活比例" prop="dauProportions" v-if="type === 1">
          <a-input-number
            placeholder="请输入日活比例"
            style="width: 290px; border-radius: 5px"
            :formatter="(value) => `${value}%`"
            :precision="2"
            :parser="(value) => value.replace('%', '')"
            :min="0"
            v-model.number.trim="query.dauProportions"
          />
        </a-form-model-item>
        <template v-if="type === 2">
          <a-form-model-item has-feedback label="请求比例" prop="requestProportions">
            <a-input-number
              placeholder="请输入请求比例"
              style="width: 290px; border-radius: 5px"
              :formatter="(value) => `${value}%`"
              :precision="2"
              :parser="(value) => value.replace('%', '')"
              :min="0"
              v-model.number.trim="query.requestProportions"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="填充比例" prop="responseProportions">
            <a-input-number
              placeholder="请输入填充比例"
              style="width: 290px; border-radius: 5px"
              :formatter="(value) => `${value}%`"
              :precision="2"
              :parser="(value) => value.replace('%', '')"
              :min="0"
              v-model.number.trim="query.responseProportions"
            />
          </a-form-model-item>
        </template>
        <template v-if="type === 3">
          <a-form-model-item has-feedback label="展示比例" prop="impressProportions">
            <a-input-number
              style="width: 290px; border-radius: 5px"
              :formatter="(value) => `${value}%`"
              :precision="2"
              :parser="(value) => value.replace('%', '')"
              :min="0"
              v-model.number.trim="query.impressProportions"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="点击比例" prop="clickProportions">
            <a-input-number
              placeholder="请输入点击比例"
              style="width: 290px; border-radius: 5px"
              :formatter="(value) => `${value}%`"
              :precision="2"
              :parser="(value) => value.replace('%', '')"
              :min="0"
              v-model.number.trim="query.clickProportions"
            />
          </a-form-model-item>
          <a-form-model-item has-feedback label="收入比例" prop="incomeProportions">
            <a-input-number
              placeholder="请输入收入比例"
              style="width: 290px; border-radius: 5px"
              :formatter="(value) => `${value}%`"
              :precision="2"
              :parser="(value) => value.replace('%', '')"
              :min="0"
              v-model.number.trim="query.incomeProportions"
            />
          </a-form-model-item>
        </template>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import {
  updateProportions, // 应用下拉框
  batchUpdateProportions // 媒体账户下拉框
} from '@/api/reportdatas'
export default {
  data () {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 }
      },
      rules: {
        dauProportions: [{ required: true, message: '请输入日活比例', trigger: 'change' }],
        requestProportions: [{ required: true, message: '请输入请求比例', trigger: 'change' }],
        responseProportions: [{ required: true, message: '请输入填充比例', trigger: 'change' }],
        impressProportions: [{ required: true, message: '请输入展示比例', trigger: 'change' }],
        clickProportions: [{ required: true, message: '请输入点击比例', trigger: 'change' }],
        incomeProportions: [{ required: true, message: '请输入收入比例', trigger: 'change' }]
      },
      query: {}
    }
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    type: {
      type: Number,
      default: 0
    },
    ifALL: {
      type: Boolean,
      default: false
    },
    placeInfo: {
      default: () => ({}),
      type: Object
    },
    placeInfoArr: {
      default: () => [],
      type: Array
    }
  },
  watch: {
    placeInfo: {
      handler (val) {
        this.query = JSON.parse(JSON.stringify(val))
      },
      deep: true
    },
    placeInfoArr: {
      handler (val) {
        if (val) {
          this.query = {
            impressProportions: 100,
            clickProportions: 100
          }
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted () {},
  computed: {
    popconfirmVisible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('modalCancel')
      }
    }
  },
  methods: {
    handleCancel () {
      this.query = {}
      this.$emit('handleCancel')
      this.popconfirmVisible = false
    },
    // 提交
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          // batchUpdateProportions -- 批量发布回收  updateProportions -- 发布回收
          const joggle = this.ifALL ? batchUpdateProportions : updateProportions
          const {
            id,
            appId = '',
            placeId = '',
            date = '',
            plat = '',
            platAccountId = '',
            dauProportions = '',
            requestProportions = '',
            responseProportions = '',
            impressProportions = '',
            clickProportions = '',
            incomeProportions = ''
          } = this.query
          const obj = {
            id,
            date,
            appId,
            placeId,
            plat: Number(plat),
            platAccountId: Number(platAccountId),
            type: this.type
          }
          const obj1 = {
            type: this.type,
            idList: this.placeInfoArr.map((item) => item.id)
          }
          const Obj = this.ifALL ? obj1 : obj
          const list = {
            1: { ...Obj, ...{ dauProportions: Number(dauProportions) } },
            2: {
              ...Obj,
              ...{ requestProportions: Number(requestProportions), responseProportions: Number(responseProportions) }
            },
            3: {
              ...Obj,
              ...{
                impressProportions: Number(impressProportions),
                clickProportions: Number(clickProportions),
                incomeProportions: Number(incomeProportions)
              }
            }
          }
          const resp = await joggle(list[this.type])
          if (resp.code === 200) {
            this.$message.success('配置成功!')
            this.$emit('changeInfo')
          }
          this.query = {}
          this.popconfirmVisible = false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.ant-form {
  padding-top: 20px;
}

.batch_advertiser_modal {
  .ant-modal-body {
    background-color: #eee;

    .add_advertiser_wrapper {
      height: 300px;
      overflow-y: auto;
      border-left: 10px solid #fff;
      border-top: 10px solid #fff;
      border-bottom: 10px solid #fff;
      background-color: #fff;
      border-radius: 5px;
    }
  }
}
</style>
